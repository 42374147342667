import React from 'react';
import { Form } from 'antd';
import { LoginCard, LoginSubmit, LoginFormItem } from '@/components/Login';
import { BorderedTitle } from '@/components/BorderedTitle';
import Session from '@/services/Session';
import Text from '@/components/Text';

const formItems = [
  {
    name: 'password',
    rules: [
      {
        required: true,
        message: 'Please enter a new password',
      },
    ],
    icon: 'lock',
    label: 'New Password',
    placeholder: 'Your new Password',
    type: 'password',
  },
  {
    name: 'password_confirmation',
    rules: [
      {
        required: true,
        message: 'Please confirm your new password',
      },
    ],
    icon: 'lock',
    label: 'New Password Confirmation',
    placeholder: 'Confirm your new password',
    type: 'password',
  },
];

const Migrated = (props) => {
  const { form, onSubmit } = props;
  const { user } = Session.get();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const confirmationParams = {
          username: user.username,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
        };

        onSubmit({
          ...confirmationParams,
          ...values,
        });
      }
    });
  };

  return (
    <LoginCard
      title={
        <h2 style={{ textAlign: 'center' }}>
          A brand new Keet app calls for a brand new password.
        </h2>
      }
      subtitle={
        <div>
          <Text>
            <b>Enter a New Password</b>
          </Text>
          <br />
          <Text>
            Your account has been upgraded to the latest version of the Keet Health platform. Please
            enter your password to complete this process.
          </Text>
        </div>
      }
    >
      <BorderedTitle>Username: {user.username}</BorderedTitle>
      <Form onSubmit={handleSubmit}>
        {formItems.map((itemProps, index) => (
          <LoginFormItem
            {...itemProps}
            key={itemProps.name}
            getFieldDecorator={form.getFieldDecorator}
            autoFocus={index === 0}
          />
        ))}
        <LoginSubmit loading={false}>Submit</LoginSubmit>
      </Form>
    </LoginCard>
  );
};

export default Migrated;
