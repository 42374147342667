import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Session from '@/services/Session';
import Redirect from '@/components/Redirect';
import { getSessionParams } from '@/utils';

const routes = {
  confirm: '/user/confirm',
  register: '/user/confirm',
  reset_password: '/user/reset_password',
  login: '/user/login',
  unlock: '/user/unlock',
};

export default withRouter((props) => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    location: { search },
  } = props;

  const params = getSessionParams(search);
  const { signature, action, challenge } = params;

  // TODO: Remove later once the routes have been changed to only come to relay
  const [routeKey, setRouteKey] = useState(action);

  useEffect(() => {
    // By moving this function inside the effect, we can clearly see the values it uses.
    async function fetchSession() {
      setLoading(true);
      try {
        const info = await Session.set({ challenge, signature });
        setClient(info.client);

        // This is set in *Session.js*
        // Because some of our old links don't contain the correct info, we set it manually
        // and pass them through the session object.
        // This should be removed once all links come to relay.
        if (info.action) {
          setRouteKey(info.action);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    }

    fetchSession();
  }, [signature, challenge]);

  if (loading) return <noscript />;
  if (error || !Session.valid()) {
    return <Redirect to="/error" />;
  }
  const redirectProps = {};
  const route = routes[routeKey];
  if (route) {
    redirectProps.to = route;
  } else if (client) {
    redirectProps.to = '';
    redirectProps.baseUrl = client.client_uri;
  } else {
    return <Redirect to="/error" />;
  }

  return <Redirect {...redirectProps} />;
});
