import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from '@/routes/User/Login';
import Consent from '@/routes/User/Login/Consent';
// import RecoverUsername from '@/routes/User/Login/RecoverUsername';
import ForgotPassword from '@/routes/User/Login/ForgotPassword';
import ErrorRoute from '@/routes/Error';
import ResetPassword from '@/routes/User/Login/ResetPassword';
import NewPassword from '@/routes/User/Login/NewPassword';
import Success from '@/routes/Success';
import Relay from '@/routes/Redirect/Relay';
import Unlock from '@/routes/User/Unlock';
import Confirm from '@/routes/User/Login/Confirm';

const routes = [
  {
    name: 'Root',
    key: 'root',
    path: '/',
    render: () => <Redirect to="/user/login" />,
  },
  {
    name: 'Relay',
    key: 'relay',
    path: '/relay',
    component: Relay,
  },
  {
    name: 'Login',
    key: 'login',
    path: '/user/login',
    component: Login,
  },
  {
    name: 'Consent',
    key: 'consent',
    path: '/user/consent',
    component: Consent,
  },
  {
    name: 'Confirm',
    key: 'confirm',
    path: '/user/confirm',
    component: Confirm,
  },
  {
    name: 'Forgot Password',
    key: 'forgot-password',
    path: '/user/forgot_password',
    component: ForgotPassword,
  },
  {
    name: 'Unlock Account',
    key: 'unlock',
    path: '/user/unlock',
    component: Unlock,
  },
  {
    name: 'Reset Password',
    key: 'reset-password',
    path: '/user/reset_password',
    component: ResetPassword,
  },
  {
    name: 'New Password',
    key: 'new-password',
    path: '/user/new_password',
    component: NewPassword,
  },
  // {
  //   name: 'Recover Username',
  //   key: 'recover-username',
  //   path: '/user/recover_username',
  //   component: RecoverUsername,
  // },
  {
    name: 'Error',
    key: 'error',
    path: '/error',
    component: ErrorRoute,
  },
  {
    name: 'Success',
    key: 'success',
    path: '/success',
    component: Success,
  },
  {
    key: 'catch-all',
    render: () => <Redirect to="/error" />,
  },
];

export default routes;
