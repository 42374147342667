import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { LoginCard } from '@/components/Login';
import styles from './Confirmed.module.less';
import { isPractitioner } from '../utils';
import AppDownloadLinks from './AppDownload';

const Confirmed = (props) => {
  const { client, user } = props;

  const onClick = () => {
    window.location.href = `${client.client_uri}?registered`;
  };

  const { metadata = {} } = client;

  return (
    <div className={styles.LoginCard}>
      <LoginCard logo={client.logo_uri}>
        <p className={styles.success}>Success - You’re registered!</p>
        {!isPractitioner(user) && (
          <AppDownloadLinks
            iosAppId={metadata.ios_app_id}
            androidAppId={metadata.android_app_id}
            {...metadata.confirmation_page}
          />
        )}
        <Button onClick={onClick}>I&apos;m ready to log in</Button>
      </LoginCard>
    </div>
  );
};

Confirmed.propTypes = {
  client: PropTypes.shape({
    logo_uri: PropTypes.string,
    metadata: PropTypes.shape({
      confirmation_page: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        messages: PropTypes.arrayOf(PropTypes.string),
      }),
      ios_app_id: PropTypes.string,
      android_app_id: PropTypes.string,
    }),
  }),
};

Confirmed.defaultProps = {
  client: {},
};

export default Confirmed;
