export const client = {
  client_id: 'keethealth-practitioner',
  client_name: 'PT Login',
  redirect_uris: ['http://localhost:3003/authorize'],
  grant_types: ['authorization_code', 'refresh_token'],
  response_types: ['code', 'token', 'id_token'],
  scope: 'openid offline practitioner admin superadmin support',
  audience: ['lvh.me'],
  owner: '',
  policy_uri: 'https://s3-us-west-2.amazonaws.com/end-user-agreement/Keet+-+Privacy+Policy+.pdf',
  allowed_cors_origins: [],
  tos_uri:
    'https://s3-us-west-2.amazonaws.com/end-user-agreement/Keet+-+End+User+Terms+of+Service+.pdf',
  client_uri: 'http://localhost:3003',
  logo_uri: 'https://pt.keetdev.com/static/keet-logo-large.a7173779.png',
  contacts: ['help@keethealth.com'],
  client_secret_expires_at: 0,
  subject_type: 'public',
  token_endpoint_auth_method: 'none',
  userinfo_signed_response_alg: 'none',
  created_at: '2019-11-20T00:34:44Z',
  updated_at: '2019-11-20T00:34:44Z',
};
