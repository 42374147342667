export const user = {
  id: '981c583c-0ca9-4906-9076-31a794631bd7',
  username: 'mockedUserName',
  email: 'development+mock@keethealth.com',
  first_name: 'Mocked',
  last_name: 'User',
  phone_number: '7145585984',
  user_metadata: {
    registration_type: '',
    organization_id: '8e64780c-c38e-4a2f-8d32-077f2ab84074',
    roles: ['patient'],
  },
  active: true,
  confirmed_at: null,
  unlock_token: 'abc',
};
