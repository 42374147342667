import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './config/routes';
import headerBG from './assets/Header-BG.png';
import './App.css';

const bgStyle = { backgroundImage: `url(${headerBG})` };

export default function App() {
  return (
    <Router>
      <div className="Container" style={bgStyle}>
        <Switch>{routes.map(renderRoute)}</Switch>
      </div>
    </Router>
  );
  function renderRoute({ key, path, component, render }) {
    return (<Route key={key} path={path} exact component={component} render={render} />)
  }
}
