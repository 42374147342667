import React from 'react';
import PropTypes from 'prop-types';
import keetLogo from '@/assets/keet-logo-lrg.png';
import styles from './index.module.less';

const Title = ({ text }) => <h1 className={styles.title}>{text}</h1>;

const LoginCard = ({ title, children, subtitle, logo }) => {
  const titleComponent = typeof title === 'string' ? <Title text={title} /> : title;

  return (
    <div className={styles.content}>
      <div className={styles.top}>
        <div className={styles.header}>
          {logo ? (
            <img alt="logo" className={styles.logo} src={logo} />
          ) : (
            <img src={keetLogo} className={styles.logo} alt="logo" />
          )}
        </div>
      </div>
      <div className={styles.main}>
        {titleComponent}
        {subtitle}
        {children}
      </div>
    </div>
  );
};

LoginCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.any,
  logo: PropTypes.node,
};

LoginCard.defaultProps = {
  title: null,
  subtitle: null,
  children: null,
  logo: undefined,
};

export default LoginCard;
