import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Redirect } from 'react-router-dom';
import { LoginCard, LoginSubmit, LoginFormItem } from '@/components/Login';
import { updateExpiredPassword } from '@/services/api';
import Session from '@/services/Session';

class NewPassword extends Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        username: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    redirectTo: undefined,
  };

  handleSuccess = () => {
    this.setState({
      redirectTo: '/user/login',
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          await updateExpiredPassword(
            Session.challenge(),
            {
              ...values,
              username: this.props.location.state.username,
            },
            this.props.authenticityToken,
          );
          this.handleSuccess();
        } catch (error) {
          const { response } = error;
          const { form } = this.props;
          if (response.errors) {
            response.errors.forEach((res) => {
              const { id, detail } = res;
              form.setFields({
                [id]: {
                  value: values[id],
                  errors: [new Error(detail)],
                },
              });
            });
          }
        }
      }
    });
  };

  renderFormItems = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const formItems = [
      {
        name: 'current_password',
        rules: [
          {
            required: true,
            message: 'Please enter your current password',
          },
        ],
        icon: 'lock',
        label: 'Password',
        placeholder: 'Your current password',
        type: 'password',
      },
      {
        name: 'password',
        rules: [
          {
            required: true,
            message: 'Please enter a new password',
          },
        ],
        icon: 'lock',
        label: 'New Password',
        placeholder: 'Your new Password',
        type: 'password',
      },
      {
        name: 'password_confirmation',
        rules: [
          {
            required: true,
            message: 'Please confirm your new password',
          },
        ],
        icon: 'lock',
        label: 'New Password Confirmation',
        placeholder: 'Confirm your new password',
        type: 'password',
      },
    ];

    return formItems.map((itemProps, index) => (
      <LoginFormItem
        {...itemProps}
        key={itemProps.name}
        getFieldDecorator={getFieldDecorator}
        autoFocus={index === 0 && true}
      />
    ));
  };

  render() {
    const session = Session.get();
    const {
      location: { state = {} },
    } = this.props;
    const isValid = state.username && session.client && Session.challenge();

    if (!isValid) {
      return <Redirect to="/error" />;
    }

    return (
      <LoginCard title="Renew Your Password">
        <Form onSubmit={this.handleSubmit}>
          {this.renderFormItems()}
          <LoginSubmit loading={false}>Reset Password</LoginSubmit>
        </Form>
      </LoginCard>
    );
  }
}

export default Form.create()(NewPassword);
