export const loginGet = {
  challenge: 'abcdefghijklmnop',
  requested_scope: ['openid', 'offline', 'admin', 'practitioner', 'superadmin', 'support'],
  requested_access_token_audience: ['lvh.me'],
  skip: false,
  subject: '',
  oidc_context: {},
  client: {
    client_id: 'keethealth-practitioner',
    client_name: 'PT Login',
    redirect_uris: ['http://localhost:3003/authorize'],
    grant_types: ['authorization_code', 'refresh_token'],
    response_types: ['code', 'token', 'id_token'],
    scope: 'openid offline practitioner admin superadmin support',
    audience: ['keetdev.com'],
    owner: '',
    policy_uri: 'https://s3-us-west-2.amazonaws.com/end-user-agreement/Keet+-+Privacy+Policy+.pdf',
    allowed_cors_origins: [],
    tos_uri:
      'https://s3-us-west-2.amazonaws.com/end-user-agreement/Keet+-+End+User+Terms+of+Service+.pdf',
    client_uri: 'http://localhost:3003',
    logo_uri: 'https://pt.keetdev.com/static/keet-logo-large.a7173779.png',
    contacts: ['help@keethealth.com'],
    client_secret_expires_at: 0,
    subject_type: 'public',
    token_endpoint_auth_method: 'none',
    userinfo_signed_response_alg: 'none',
    created_at: '2019-11-20T00:34:44Z',
    updated_at: '2019-11-20T00:34:44Z',
  },
  request_url:
    'http://localhost:4444/oauth2/auth?redirect_uri=https%3A%2F%2Fpt.keetdev.com%2Fauthorize\u0026client_id=keethealth-practitioner\u0026response_type=code\u0026state=DLkJVTZEjn\u0026scope=openid%20offline%20admin%20practitioner%20superadmin%20support\u0026nonce=Y1BYlwDyz6\u0026prompt=consent\u0026access_type=offline\u0026audience=keetdev.com\u0026code_challenge=NPDuPRS_ew1PB4T_5Z2JcAJKREbwKFJFKaS-yAO0qzg\u0026code_challenge_method=S256',
  session_id: '',
};

export const loginSuccess = {
  redirect_to: 'http://localhost:4001/user/consent',
};

export const loginFailure = {
  errors: [
    {
      status: '422',
      code: '222',
      source: { pointer: '/data/attributes/password' },
      title: 'Invalid Password',
      detail: 'We were unable to find a valid account with the information you provided.',
    },
  ],
};
