import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Text.module.less';

const Text = (props) => {
  const { children, className, ...rest } = props;

  const base = [styles.center];

  return (
    <div className={classNames(...base, className)} {...rest}>
      {children}
    </div>
  );
};

const Flash = (props) => {
  return <Text className={styles.flash} {...props} />;
};

Text.propTypes = {
  children: PropTypes.any,
};

Text.defaultProps = {
  children: null,
};

Text.Flash = Flash;

export default Text;
