import React from 'react';
import Redirect from '@/components/Redirect';
import { acceptConsentRequest } from '@/services/api';

const Consent = ({ location: { search } }) => {
  const getChallenge = () => {
    const params = new URLSearchParams(search);
    return params.get('consent_challenge');
  };

  acceptConsentRequest(getChallenge()).catch(() => <Redirect to="/user/login" />);

  return null;
};

export default Consent;
