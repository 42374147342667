import React from 'react';
import classNames from 'classnames';
import { Button as AntButton } from 'antd';
import styles from './index.module.less';

const Button = ({ className, ...rest }) => {
  const clsString = classNames(styles.submit, className);
  return (
    <AntButton size="large" className={clsString} type="primary" htmlType="submit" {...rest} />
  );
};

export default Button;
