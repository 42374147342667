import { rest } from 'msw';
import {
  loginGet,
  loginSuccess,
  // loginFailure,
  consentSuccess,
  introspectSuccess,
  // introspectFailure,
} from './fixtures';

const { get, post, put } = rest;

export const handlers = [
  get('/api/login', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(loginGet));
  }),
  post('/api/login', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(loginSuccess));
    // uncomment this line instead to simulate a failure
    // return res(ctx.status(422), ctx.json(loginFailure));
  }),
  get('/api/consent', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(consentSuccess));
  }),
  post('/api/login/forgot_password', (req, res, ctx) => {
    return res(ctx.status(204), ctx.json());
  }),
  post('/api/login/password', (req, res, ctx) => {
    return res(ctx.status(204), ctx.json());
  }),
  put('/api/unlock', (req, res, ctx) => {
    return res(ctx.status(204), ctx.json());
  }),
  put('/api/confirm', (req, res, ctx) => {
    return res(ctx.status(204), ctx.json());
  }),
  post('/api/signature/introspect', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(introspectSuccess));
    // return res(ctx.status(422), ctx.json(introspectFailure));
  }),
];
