import React from 'react';
import Button from '@/components/Button';
import errorImage from '@/assets/formeater.png';
import Session from '@/services/Session';
import styles from './index.module.less';

const defaultTitle = 'Success';
const defaultMessage = 'The action was successful';

const SuccessComponent = ({ title = defaultTitle, message = defaultMessage }) => {
  const onClick = () => {
    const response = Session.get();
    const { client } = response;
    window.location.href = client.client_uri;
  };

  return (
    <div className={styles.content}>
      <div className={styles.main}>
        <div className={styles.message}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.title}>{message}</p>
        </div>
        <div className={styles.image}>
          <img alt="not_found" src={errorImage} className={styles.logo} />
        </div>
        <Button onClick={onClick}>Back to login</Button>
      </div>
    </div>
  );
};

export default SuccessComponent;
