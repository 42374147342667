import { client } from './client';
import { user } from './user';

export const introspectSuccess = {
  session: {
    user: user,
    client: client,
  },
};

export const introspectFailure = {
  errors: [{ status: 400, detail: 'The registration link is not valid.' }],
};
