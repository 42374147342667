import React from 'react';
import { Redirect } from 'react-router-dom';

export default function RedirectComponent(props) {
  const { baseUrl, ...rest } = props;

  if (baseUrl) {
    window.location.href = `${baseUrl}/${props.to}`;

    return <noscript />;
  }

  return <Redirect {...rest} />;
}
