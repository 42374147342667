import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';

import appStoreImage from '@/assets/app-store-badge.png';
import googlePlayImage from '@/assets/google-play-badge.png';
import styles from './Confirmed.module.less';

const generateIosLink = (id) => `https://apps.apple.com/us/app/${id}`;
const generateAndroidLink = (id) => `https://play.google.com/store/apps/details?id=${id}&hl=en_US`;

const Header = ({ children, ...rest }) => (
  <h1 className={styles.connect} {...rest}>
    {children}
  </h1>
);

const Subtitle = ({ children, ...rest }) => <h3 {...rest}>{children}</h3>;

const Bullets = ({ items }) => (
  <ul className={styles.list}>
    {map(items, function renderText(text) {
      return <li key={kebabCase(text)}>{text}</li>;
    })}
  </ul>
);

const AppStoreBadge = ({ link }) => (
  <a href={link}>
    <img src={appStoreImage} alt="app store badge" />
  </a>
);

const PlayStoreBadge = ({ link }) => (
  <a href={link}>
    <img src={googlePlayImage} alt="google play badge" />
  </a>
);

const AppDownload = ({ iosAppId, androidAppId, title, subtitle, messages }) => (
  <>
    <Header>{title}</Header>
    <div className={styles.listWrapper}>
      <Subtitle>{subtitle}</Subtitle>
      <Bullets items={messages} />
    </div>
    <div className={styles.imageWrapper}>
      <AppStoreBadge link={generateIosLink(iosAppId)} />
      <PlayStoreBadge link={generateAndroidLink(androidAppId)} />
    </div>
  </>
);

AppDownload.propTypes = {
  iosAppId: PropTypes.string,
  androidAppId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
};

AppDownload.defaultProps = {
  iosAppId: 'id1529474491',
  androidAppId: 'com.keethealth.patient.app',
  title: 'Recover on the Go',
  subtitle: 'Download the NEW patient app to:',
  messages: [
    'View your progress',
    'Access exercises',
    'Message your therapist',
    'Set personal reminders',
    'Keep up with tasks between visits',
  ],
};

export default AppDownload;
