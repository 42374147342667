import React from 'react';
import styles from './index.module.less';

export const BorderedTitle = ({ children }) => (
  <div className={styles.subtitleWrapper}>
    <div className={styles.subtitle}>
      <h3>{children}</h3>
    </div>
  </div>
);
