import React, { useEffect, useState } from 'react';
import { unlock } from '@/services/api';
import Session from '@/services/Session';
import Redirect from '@/components/Redirect';

const Unlock = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    unlock(Session.signature())
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  if (error) {
    return <Redirect to="/error" />;
  }

  if (loading) return null;

  return <Redirect to="/success" />;
};

export default Unlock;
