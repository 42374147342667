import React from 'react';
import { Form, Input, Icon } from 'antd';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import styles from './index.module.less';

const FormItem = Form.Item;

const LoginFormItem = (props) => {
  const {
    name,
    rules,
    icon,
    placeholder,
    type,
    getFieldDecorator,
    label,
    errors,
    disabled,
    autoFocus,
    initialValue,
  } = props;
  let submitErrors = [];
  if (errors) {
    submitErrors = _.groupBy(errors, 'id');
  }

  let FormInput = (
    <Input
      size="large"
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      prefix={icon && <Icon type={icon} className={styles.prefixIcon} />}
      autoFocus={autoFocus}
    />
  );

  if (type === 'password') {
    FormInput = (
      <Input.Password
        size="large"
        placeholder={placeholder}
        type={type}
        icon={icon}
        prefix={icon && <Icon type={icon} className={styles.prefixIcon} />}
      />
    );
  } else if (type === 'phone_number') {
    FormInput = (
      <InputMask
        className="ant-input ant-input-lg"
        mask="(999) 999-9999"
        placeholder="123-456-7890"
      />
    );
  }

  const options = { rules };
  if (initialValue) {
    options.initialValue = initialValue;
  }

  return (
    <FormItem className={styles.loginFormItem} label={label || placeholder}>
      {getFieldDecorator(name, options)(FormInput)}
      <div className={styles.error}>
        {errors && submitErrors[name] ? submitErrors[name][0].title : ''}
      </div>
    </FormItem>
  );
};

export default LoginFormItem;
