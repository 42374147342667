import React from 'react';
import errorImage from '@/assets/500.png';
import styles from './index.module.less';

const defaultTitle = 'The registration link is not valid';
const defaultMessage =
  'Please login. If you continue to have an issue accessing your account please contact support.';

const ErrorComponent = ({ title = defaultTitle, message = defaultMessage }) => (
  <div className={styles.content}>
    <div className={styles.main}>
      <div className={styles.message}>
        <h1 className={styles.title}>{title}</h1>
        <p>{message}</p>
      </div>
      <div className={styles.image}>
        <img alt="not_found" src={errorImage} className={styles.logo} />
      </div>
    </div>
  </div>
);

export default ErrorComponent;
