const codeMessage = {
  200: 'The server successfully returned the requested data. ',
  201: 'The new or modified data was successful. ',
  202: 'A request has been queued in the background (asynchronous task). ',
  204: 'The data was deleted successfully. ',
  400: 'There was an error in the issued request. The server did not create or modify data. ',
  401: 'User does not have permission (token, username, password is wrong). ',
  403: 'The user is authorized but the access is forbidden. ',
  404: 'The request was issued for a non-existent record and the server did not perform the operation. ',
  406: 'The requested format is not available. ',
  410: 'The requested resource is permanently deleted and will not be regained. ',
  422: 'A validation error occurred while creating an object. ',
  500: 'An error occurred on the server. Please check the server. ',
  502: 'The gateway is wrong. ',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained. ',
  504: 'The gateway timed out. ',
};

export async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const jsonResponse = await response.json();
  const errortext = jsonResponse.errors || codeMessage[response.status] || response.statusText;
  const error = new Error(errortext);
  error.status = response.status;
  error.response = jsonResponse;
  throw error;
}
