import React, { Component } from 'react';
import { Form } from 'antd';
import { Redirect } from 'react-router-dom';
import { LoginCard, LoginSubmit, LoginFormItem } from '@/components/Login';

import { BorderedTitle } from '@/components/BorderedTitle';
import { resetPassword } from '@/services/api';
import Session from '@/services/Session';

class ResetPassword extends Component {
  state = {
    errors: [],
  };

  handleSuccess = () => {
    this.setState({
      redirectTo: '/success',
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            errors: [],
          });
          await resetPassword(Session.signature(), values);
          this.handleSuccess();
        } catch (error) {
          this.setState({
            errors: error.response.errors,
          });
        }
      }
    });
  };

  componentDidMount() {
    this.deprecated_checkSession();
  }

  // TODO: Remove
  deprecated_checkSession = async () => {
    const {
      history,
      location: { search },
    } = this.props;
    const searchParams = new URLSearchParams(search);
    const signature = searchParams.get('id');

    if (signature) {
      history.replace(`/relay?signature=${signature}&action=reset_password`);
    }
  };

  renderFormItems = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { errors } = this.state;

    const formItems = [
      {
        name: 'password',
        rules: [
          {
            required: true,
            message: 'Please enter a new password',
          },
        ],
        icon: 'lock',
        label: 'New Password',
        placeholder: 'Your new Password',
        type: 'password',
      },
      {
        name: 'password_confirmation',
        rules: [
          {
            required: true,
            message: 'Please confirm your new password',
          },
        ],
        icon: 'lock',
        label: 'New Password Confirmation',
        placeholder: 'Confirm your new password',
        type: 'password',
      },
    ];

    return formItems.map((itemProps, index) => (
      <LoginFormItem
        {...itemProps}
        key={itemProps.name}
        getFieldDecorator={getFieldDecorator}
        errors={errors}
        autoFocus={index === 0}
      />
    ));
  };

  render() {
    if (!Session.valid()) {
      return <Redirect to="/error" />;
    }

    const { redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const { user } = Session.get();

    return (
      <LoginCard title="Enter a New Password">
        <BorderedTitle>{user.email}</BorderedTitle>
        <Form onSubmit={this.handleSubmit}>
          {this.renderFormItems()}
          <LoginSubmit loading={false}>Reset Password</LoginSubmit>
        </Form>
      </LoginCard>
    );
  }
}

export default Form.create()(ResetPassword);
