import React from 'react';
import { Form } from 'antd';
import Button from '../Button';

const FormItem = Form.Item;

const LoginSubmit = (props) => {
  return (
    <FormItem>
      <Button {...props} />
    </FormItem>
  );
};

export default LoginSubmit;
