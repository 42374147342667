import React, { Component } from 'react';
import { Form, notification } from 'antd';
import includes from 'lodash/includes';
import { LoginCard, LoginSubmit } from '@/components/Login';
import Input from '@/components/FormInput';
import { BorderedTitle } from '@/components/BorderedTitle';
import Redirect from '@/components/Redirect';
import './Login.css';
import { completeRegistration } from '@/services/api';
import Session from '@/services/Session';
import { validatePhone } from '@/utils';
import { isPractitioner } from './utils';
import Migrated from './Migrated';
import Confirmed from './Confirmed';

class Confirm extends Component {
  state = {
    isConfirmed: false,
  };

  onError = (errors = [], values = {}) => {
    const { form } = this.props;

    errors.forEach((res) => {
      const { id, detail } = res;

      // It maps to a field on the form
      if (id) {
        form.setFields({
          [id]: {
            value: values[id],
            errors: [new Error(detail)],
          },
        });
      } else {
        try {
          const description = detail.toString();
          notification.error({ message: 'Server Error', description });
        } catch (e) {
          console.error('error: detail cannot be converted to string', e);
        }
      }
    });
  };

  completeRegistration = async (values) => {
    const token = Session.signature();
    try {
      await completeRegistration(token, values);
      this.setState({ isConfirmed: true });
    } catch (e) {
      const { response } = e;
      if (response.errors) {
        this.onError(response.errors, values);
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.completeRegistration(values);
      }
    });
  };

  renderInformationItems = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { user = {} } = Session.get();

    const formItems = [
      {
        label: 'First Name',
        name: 'first_name',
        initialValue: user.first_name,
        rules: [
          {
            required: true,
            message: 'Please enter a first name',
          },
        ],
        placeholder: 'e.g. Jen',
      },
      {
        label: 'Last Name',
        name: 'last_name',
        initialValue: user.last_name,
        rules: [
          {
            required: true,
            message: 'Please enter a last_name',
          },
        ],
        placeholder: 'e.g. Johnson',
      },
      {
        label: 'Email',
        name: 'email',
        disabled: true,
        initialValue: user.email,
      },
      {
        label: 'Phone Number',
        name: 'phone_number',
        type: 'phone_number',
        initialValue: user.phone_number,
        rules: [
          {
            validator: validatePhone,
          },
        ],
      },
    ];

    return formItems.map((itemProps) => (
      <Input {...itemProps} key={itemProps.name} getFieldDecorator={getFieldDecorator} />
    ));
  };

  renderAuthItems = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { user = {} } = Session.get();
    const { registration_type, roles } = user.user_metadata;
    const invitedPatient = registration_type === 'invite' && includes(roles, 'patient');

    const formItems = [
      {
        label: 'Username',
        name: 'username',
        disabled: isPractitioner(user),
        initialValue: invitedPatient ? null : user.username,
        rules: [
          {
            required: true,
            message: 'Please enter a username',
          },
        ],
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
        rules: [
          {
            required: true,
            message: 'Please enter a password',
          },
        ],
      },
      {
        label: 'Password confirmation',
        name: 'password_confirmation',
        type: 'password',
        rules: [
          {
            required: true,
            message: 'Please enter password confirmation',
          },
        ],
      },
    ];

    return formItems.map((itemProps) => (
      <Input {...itemProps} key={itemProps.name} getFieldDecorator={getFieldDecorator} />
    ));
  };

  render() {
    const { isConfirmed } = this.state;
    const session = Session.get();

    if (!Session.valid()) {
      return <Redirect to="/error" />;
    }

    if (session.user && session.user.confirmed_at) {
      return <Redirect to="" baseUrl={session.client.client_uri} />;
    }

    const { client, user = {} } = session;
    const { registration_type } = user.user_metadata;
    const isMigratedUser = registration_type === 'migrated';

    if (isConfirmed) {
      return <Confirmed {...this.props} client={client} user={user} />;
    }

    if (isMigratedUser) {
      return <Migrated {...this.props} onSubmit={this.completeRegistration} />;
    }

    return (
      <LoginCard logo={client.logo_uri}>
        <Form onSubmit={this.handleSubmit}>
          <BorderedTitle>Confirm Your Information</BorderedTitle>
          {this.renderInformationItems()}
          <BorderedTitle>Set Your Username & Password</BorderedTitle>
          {this.renderAuthItems()}
          <LoginSubmit loading={false}>Confirm</LoginSubmit>
        </Form>
        <div>
          By clicking Confirm, you accept the&nbsp;
          <a
            href="https://keet-email-assets.s3.us-west-2.amazonaws.com/2022/KEET+-+EULA+revised+2_2022+FINAL.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            End User Terms
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://s3-us-west-2.amazonaws.com/end-user-agreement/Keet+-+Privacy+Policy+.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </LoginCard>
    );
  }
}

export default Form.create()(Confirm);